import BandwagonDotCom from "../../components/BandwagonDotCom"
import React from "react"
import TeamMember from "../../components/TeamMember"

import { StaticImage } from "gatsby-plugin-image"

const MonetRouse = () => <BandwagonDotCom>
  <TeamMember
        headshot=<StaticImage src="../../images/monet-rouse-headshot.png"
                alt="Monét Rouse" />
        name="Monét Rouse"
        position="Head of Product"
  >
      <p>
        Monét Rouse is a graduate of Clemson University where she received her
        Bachelors degree in Graphic Communications (B.Sc.) with a minor in
        Business Administration. Monét received her Master’s degree at Indiana
        University in Human Computer Information and interned at The Walt Disney
        Company immediately after graduating. At Disney, her passion for
        Diversity, Equity, and Inclusivity (especially in the STEM field) lead
        her to Chair the Consumer and Business Insights committee for the Black
        Employee Resource Group and later Co-Lead the group.
      </p>
      <h3>Fun Fact</h3>
      <hr/>
      <p>
        Monét paints! Like her namesake.
      </p>
      <h3>Why I love Bandwagon</h3>
      <hr/>
      <p>
        I trust Harold’s vision, and where Bandwagon is headed is an interesting
        space to be in technology-wise.
      </p>
  </TeamMember>
</BandwagonDotCom>;

export default MonetRouse
